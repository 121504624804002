.releasenote-container {
    padding: 0px 10px;
    /* width: calc(55% - 72px); */
    /* height: calc(60vh - 15px); */
}

.releasenote-content {
    margin-top: 10px !important;
    height: calc(100% - 60px);
    overflow:auto;
}

.releasenote-content table thead tr th {
    font-family: ABBVoice-bold;
    padding: 8px 16px;
    border-bottom: 1px solid #bababa;
}

.releasenote-content table thead tr {
    width: auto;
    clear: both;
}

.releasenote-content table tbody tr td {
    padding: 8px;
    border-bottom: 1px solid #dbdbdb;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.releasenote-content table {
    table-layout: auto;
    border-collapse: collapse;
    margin-bottom: 16px;
}

.releasenote-content p {
    margin-bottom: 8px;
    /* color: #696969; */
}

.releasenote-content h4 {
    /* font-family: ABBVoice-bold; */
}

.releasenote-content .releasenote-known-issues {
    margin-bottom: 2em;
}

.releasenote-footer {
    padding-top: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media only screen and (max-width: 961px) {
    .releasenote-container {
        width: calc(100% - 72px);
    }
}