.about-page-container {
    display: flex;
    height: 90vh;
  }
  
  .about-menu-container {
    /* background: #f5f5f5; */
    /* border-right: 1px solid #e8e8e8; */
    width: 20%;
    height: 90vh; /* Ensures the menu takes the full height */
  }
  
  .about-menu {
    height: 100%; /* Ensures the menu items are vertically aligned */
    border: none;
  }

  .about-menu  .menuItem{
    font-size: 18px;
    font-family: 'ABBVoice-bold';
    color: #1f1f1f;
  }
  
  .about-menu .ant-menu-item-selected {
    /* background-color: #e0f0ff !important; */
  }
  
  .about-content-container {
    flex: 1;
    background: #dbdbdb;
    padding: 20px;
    width: 80%; /* Ensures the content takes 80% width */
    overflow-y: auto;
  }
  
  .about-content {
    /* background: #fff; */
    padding: 10px;
    border-radius: 8px;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  }
  .pki-content-container {
    position: relative;
    padding: 20px;
    
  }
  
  .download-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #1f1f1f;
    cursor: pointer;
    border-radius: 20px;
    padding: 10px 20px;
    font-weight: 500;
    border: none;
    color: white;
    font-family: ABBVoice;
  }
  
  