.App{
    padding: 10px;
    overflow-x: hidden;
}

.contentApp{
    padding: 0px !important;
    
}
.graphs-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-top: 10px;
  }