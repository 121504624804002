.App {
  font-family: ABBvoice;
}

.landing-cards-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex: 1;
  flex-wrap: nowrap;
  overflow: auto;
  padding: 0 10px;
}

.landing-card {
  background-color: #ececec;
  height: 200px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: border-color 0.3s ease;
  cursor: pointer;
}

.landing-card:hover {
  border-color: #1890ff;
}

.card-title {
  font-size: 20px; 
  font-weight: bold;
  text-align: center;
  margin-bottom: 8px;
}

.card-description {
  font-size: 16px; 
  text-align: center;
}