.App{
    padding: 10px;
    overflow-x: hidden;
}

.headingLinks{
    padding: 20px;
}

.heading-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .quickLinks-container {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 20px;
  }
  
  .linkicon {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .icon {
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .icon-link {
    text-decoration: none; 
    color: inherit; 
  }
  
  .icon-link:hover .icon {
    color: #007bff;
  }
  
  .description {
    font-size: 16px;
    font-family: "ABBVoice" !important;
  }