.ant-modal-title {
    font-family: 'ABBVoice', sans-serif;
  }
  
  .ant-modal-body {
    font-family: 'ABBVoice', sans-serif;
    font-size: medium;
  }
  
  .ant-btn-primary {
    background-color: #1f1f1f;
    border-color: #1f1f1f;
    border-radius: 30px;
  }
  
  .ant-btn {
    border-radius: 30px;
    font-family: 'ABBVoice Regular', sans-serif;
  }