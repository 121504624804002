.App{
    padding: 10px;
}

.ant-popover-inner .Heading_container{
   width: 93% !important;
}


.ant-form  label{
    font-size: 15px !important;
    font-family: "ABBVoice";
}


.ant-form  input{
    font-size: 15px !important;
    font-family: "ABBVoice";
}

.activeView{
    background:#ebebeb;
    
}
.viewOption{
    padding:2px 10px;
    border-radius:10px;
    cursor: pointer;
    border:1px solid transparent;
    /* transition: 0.2s; */
}
.viewOption:hover{
    border-color: #ebebeb;
   
}