.App {
  padding: 5px;
  /* overflow-x: hidden; */
  font-family: ABBVoice;
}

.contentApp {
  padding: 0 !important;
}

.piechart-container {
  display: flex;
  flex-direction: row;
  /* gap: 20px; */
  justify-content: space-around;
  /* align-items: flex-start; */
  /* padding: 20px 0; */
  margin-top: -10px;
  flex:1
}

.piechart-item {
  display: flex;
  flex-direction: row;
  /* gap: 10px; */
  align-items: center;
}

.chart-title {
  font-size: 1rem;
  font-weight: bold; 
  margin-bottom: 10px; 
}

.piechart-container > div {
  /* flex: 1; */
  /* max-width: 250px; */
  text-align: center;
}

.piechart-container .recharts-responsive-container {
  width: 100% !important;
  height: auto;
}

.piechart-container span {
  font-size: 1rem;
  font-weight: bold; 
  margin-bottom: 10px;
  display: block;
  text-align: center;
}       

.piechart-container .recharts-pie {
  transform: scale(1);
}

.details {
  font-size: 14px;
  margin-top: 30px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.details div {
  display: inline-block;
  white-space: nowrap;
}
